<template>
  <v-card class="mx-auto booking-view" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <v-toolbar class="px-0" color="#ffffff" prominent elevation="3">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="toolbar-title">Booking</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="refreshPage">
        <v-icon color="#007BFF">mdi-reload</v-icon>
      </v-btn>

      <!-- Using v-slot:extension for tabs -->
      <template v-slot:extension>
        <v-tabs v-model="tabs" grow>
          <v-tab :value="0">
            <v-icon>mdi-format-list-bulleted</v-icon>
            {{ $t('booking.book-now-tab') }}
          </v-tab>
          <v-tab :value="1">
            <v-icon>mdi-format-line-spacing</v-icon>
            {{ $t('booking.bookings') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-window v-model="tabs">
      <v-window-item :value="0">
        <v-card>
          <BookingPoisList />
        </v-card>
      </v-window-item>
      <v-window-item :value="1">
        <v-card>
          <UserBookings />
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { ref, defineComponent, watch, onMounted } from 'vue'
import UserBookings from '@/components/general/UserBookings.vue'
import BookingPoisList from '@/components/general/BookingPoisList.vue'
import { getToolbarLogoImage, getBottomBarColor } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BookingView',
  components: {
    BookingPoisList,
    UserBookings,
  },
  setup() {
    const tabs = ref(0) // Starts with Book Now tab
    const router = useRouter()
    let startX = 0
    const logo = ref('')
    const tabColor = ref('')

    const handleTouchStart = e => {
      startX = e.touches[0].clientX // Get the first touch point
    }

    const handleTouchEnd = e => {
      const endX = e.changedTouches[0].clientX // Get the point where touch ended
      const diffX = startX - endX // Calculate the difference in start and end position

      if (diffX > 50) {
        // Swipe Left
        tabs.value = Math.min(tabs.value + 1, 1) // Increment tabs index, with a max of 1
      } else if (diffX < -50) {
        // Swipe Right
        tabs.value = Math.max(tabs.value - 1, 0) // Decrement tabs index, with a min of 0
      }
    }

    // Watch for tab changes to adjust routing
    watch(tabs, newValue => {
      if (newValue === 0) {
        router.push({ name: 'BookingPoisList' })
      } else {
        router.push({ name: 'UserBookings' })
      }
    })

    // Initialize component with specific tab based on the route
    const initializeComponent = () => {
      if (router.currentRoute.value.name === 'BookingPoisList') {
        tabs.value = 0 // Corresponds to <BookingPoisList>
      } else if (router.currentRoute.value.name === 'UserBookings') {
        tabs.value = 1 // Corresponds to <UserBookings>
      }
    }

    // Method to handle hot refresh
    const refreshPage = () => {
      router.go(0)
    }

    onMounted(async () => {
      tabColor.value = await getBottomBarColor()
      logo.value = await getToolbarLogoImage()
      initializeComponent()
    })

    return {
      tabs,
      logo,
      tabColor,
      handleTouchStart,
      handleTouchEnd,
      refreshPage,
    }
  },
})
</script>

<style scoped>
.booking-view {
  box-shadow: none;
}

.toolbar-icon {
  height: 40px;
  align-self: center;
  margin-left: 1rem;
}

.toolbar-title {
  font-weight: 500;
  font-size: 1.5rem;
  color: #333333;
}

.v-toolbar {
  background-color: #ffffff;
  color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.v-tab {
  font-weight: bold;
  color: #007bff;
  /* Default color for non-active tabs */
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-tab v-icon {
  color: #007bff;
  /* Default icon color for non-active tabs */
  margin-right: 8px;
}

.v-tab-item--selected,
.v-tab--selected {
  background-color: #007bff !important;
  /* Background color for active tab */
  color: #ffffff !important;
  /* Text color for active tab */
}

.v-tab-item--selected v-icon,
.v-tab--selected v-icon {
  color: #ffffff !important;
  /* Icon color for active tab */
}

.v-tabs {
  border-bottom: 2px solid #007bff;
}
</style>
<!-- BookingView Component
This component provides a user interface for booking-related functionalities. It includes a toolbar with a logo, a title, and a refresh button. The toolbar also contains tabs for navigating between different booking views.

The toolbar has a prominent elevation and a white background color. The logo is displayed on the left side, followed by the title "Booking". On the right side, there is a refresh button with a reload icon that allows users to refresh the page.

Below the toolbar, there are two tabs: "Book Now" and "Bookings". The "Book Now" tab displays a list of points of interest (POIs) that users can book. The "Bookings" tab shows the user's current bookings.

Users can switch between tabs by clicking on them or by swiping left or right on touch devices. Swiping left switches to the next tab, while swiping right switches to the previous tab.

The component uses Vue Router to navigate between different views based on the selected tab. When the "Book Now" tab is selected, the router navigates to the "BookingPoisList" view. When the "Bookings" tab is selected, the router navigates to the "UserBookings" view.

The component also initializes with a specific tab based on the current route. If the route is "BookingPoisList", the "Book Now" tab is selected. If the route is "UserBookings", the "Bookings" tab is selected.

Visual feedback is provided through the active tab's background color and text color. The active tab has a blue background color and white text color, while the inactive tabs have a white background color and blue text color.

The component also includes a method to handle hot refresh, which reloads the page when the refresh button is clicked.

Overall, this component enhances the user experience by providing a clean and intuitive interface for booking-related functionalities, with easy navigation and visual feedback. -->
