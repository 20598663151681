<template>
	<v-container style="height: 100vh" fluid class="no-permission-page fill-height">
		<v-row class="fill-height" justify="center" align="center">
			<v-col cols="12" sm="8" md="6">
				<v-card dark class="elevation-12 no-permission-card">
					<v-toolbar dark color="red">
						<v-toolbar-title>
							<v-icon large class="mr-3">mdi-alert-circle-outline</v-icon>
							No Permission
						</v-toolbar-title>
					</v-toolbar>
					<v-card-text class="text-center white--text">
						<div class="display-1 mb-4">Oops!</div>
						<div class="title mb-2">It seems you don't have permission to access this page.</div>
						<div class="caption">If you believe this is an error, please contact your administrator.</div>
					</v-card-text>
					<v-card-actions class="justify-center">
						<v-btn large color="primary" dark @click="logout">
							<v-icon left>mdi-logout</v-icon>
							Logout
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { useOAuthService } from "@/services/OAuthService"; // Assuming you have an OAuth service for handling authentication

export default {
	name: "NoPermission",
	setup() {
		const { logOut } = useOAuthService(); // Using the composition API to import logout function

		const logout = () => {
			logOut(); // Call logout function
		};

		return {
			logout,
		};
	},
};
</script>

<style scoped>
.no-permission-page {
	background: #000;
}

.no-permission-card {
	background: #222;
	border-radius: 10px;
}

/* Further styling */
</style>

<!-- NoPermission Component

This component is designed to inform users that they do not have the necessary permissions to access a particular page within the application. 

The main features of this component include:

1. A full-height container that ensures the content is vertically and horizontally centered on the screen, providing a clear and focused message to the user.
2. A card element with a dark theme and elevated styling to draw attention to the message. The card has rounded corners for a modern look.
3. A toolbar at the top of the card with a red background and an alert icon, indicating a warning or error state. The toolbar title clearly states "No Permission".
4. The card text includes a large "Oops!" message, followed by a title and a caption that explain the situation to the user. The text is centered and styled in white for readability against the dark background.
5. A button labeled "Logout" is provided at the bottom of the card. This button is styled with a primary color and includes a logout icon. When clicked, it triggers the logout function, allowing the user to log out of the application.

Visual feedback is delivered through the use of colors and icons. The red toolbar and alert icon immediately signal an issue, while the primary-colored logout button offers a clear call to action. The dark background and white text ensure that the message is easily readable.

This component enhances the user experience by providing a clear and concise message about permission issues and offering a straightforward way to log out and potentially resolve the issue by logging in with different credentials.

Users can leverage this component within the app to handle scenarios where access control is enforced, ensuring that they are informed about permission issues and given an option to take corrective action.

-->