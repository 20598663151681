// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/general/HomeView.vue'
import AuthCallback from '@/views/general/auth/AuthCallback.vue'
import LoggedOut from '@/views/general/auth/LoggedOut.vue'
import Unauthorized from '@/views/general/auth/Unauthorized.vue'
import NoPermissionView from '@/views/general/NoPermissionView.vue'
import { useOAuthService } from '@/services/OAuthService.js'
import LoginPage from '@/views/general/LoginPage.vue'
import BuildingView from '@/views/general/BuildingView.vue'
import BookingView from '@/views/general/BookingView.vue'
import LocksView from '@/views/general/LocksView.vue'
import MenuView from '@/views/general/MenuView.vue'
import PoisListView from '@/views/general/PoisListView.vue'
import InfoCenterView from '@/views/general/InfoCenterView.vue'
import UserBookings from '@/components/general/UserBookings.vue'
import RoomControlPois from '@/components/general/RoomControlPois.vue'
import { getBookingPoisListComponent } from './routerHelper'
import CreateEditBooking from '@/components/general/CreateEditBooking.vue'
import MarketingView from '@/views/general/MarketingView.vue'
import RoomControl from '@/components/general/RoomControl.vue'
import Vouchers from '@/components/general/Vouchers.vue'
import ChatUsersList from '@/components/general/ChatUsersList.vue'
import Chat from '@/components/general/Chat.vue'
import Elevator from '@/components/general/Elevator.vue'
import AnalyticsList from '@/components/general/AnalyticsList.vue'
import OfficeAnalytics from '@/components/general/OfficeAnalytics.vue'
import OccupancyAnalytics from '@/components/general/OccupancyAnalytics.vue'
import BookingsManagement from '@/components/general/BookingsManagement.vue'
import UsersManagement from '@/components/general/UsersManagement.vue'
import UsersList from '@/components/general/UsersList.vue'
import GroupsList from '@/components/general/GroupsList.vue'
import RolesList from '@/components/general/RolesList.vue'
import PoiDetails from '@/components/general/PoiDetails.vue'
import FeedbackForm from '@/components/general/FeedbackForm.vue'
import UserProfile from '@/components/general/UserProfile.vue'
import Incidents from '../components/general/Incidents.vue'
import CreateEditInfoItem from '@/components/general/ChildComponents/CreateEditInfoItem.vue'
import Functions from '@/components/general/Functions.vue'
import AdminIncidentsManagement from '@/components/general/AdminIncidentsManagement.vue'
import CreateEditUser from '@/components/general/ChildComponents/CreateEditUser.vue'
import CreateEditGroup from '@/components/general/ChildComponents/CreateEditGroup.vue'
import CreateEditRole from '@/components/general/ChildComponents/CreateEditRole.vue'
import SlidoView from '@/components/general/SlidoView.vue'
import AdminFeedbacks from '@/components/general/ChildComponents/AdminFeedbacks.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/building',
    name: 'BuildingView',
    component: BuildingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/marketing',
    name: 'MarketingView',
    component: MarketingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'ChatUsersList',
    component: ChatUsersList,
    meta: { requiresAuth: true },
  },
  {
    path: '/users/:email',
    name: 'Chat',
    component: Chat,
    meta: { requiresAuth: true },
  },
  {
    path: '/elevator',
    name: 'Elevator',
    component: Elevator,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics',
    name: 'AnalyticsList',
    component: AnalyticsList,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics/officedays',
    name: 'OfficeAnalytics',
    component: OfficeAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/analytics/occupancy',
    name: 'OccupancyAnalytics',
    component: OccupancyAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/incidents',
    name: 'Incidents',
    component: Incidents,
    meta: { requiresAuth: true },
  },

  {
    path: '/booking',
    name: 'BookingView',
    component: BookingView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'pois-list',
        name: 'BookingPoisList',
        component: () => getBookingPoisListComponent(), // Dynamic import based on customer
        meta: { requiresAuth: true },
      },
      {
        path: 'user-bookings',
        component: UserBookings,
        name: 'UserBookings',
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/menu/booking-management',
    name: 'BookingsManagement',
    component: BookingsManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management',
    name: 'UsersManagement',
    component: UsersManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/users',
    name: 'UsersList',
    component: UsersList,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/groups',
    name: 'GroupsList',
    component: GroupsList,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/roles',
    name: 'RolesList',
    component: RolesList,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/users/new',
    name: 'new-user',
    component: CreateEditUser,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/users/:id',
    name: 'edit-user',
    component: CreateEditUser,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/groups/new',
    name: 'new-group',
    component: CreateEditGroup,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/groups/:id',
    name: 'edit-group',
    component: CreateEditGroup,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/roles/new',
    name: 'new-role',
    component: CreateEditRole,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/slido',
    name: 'SlidoView',
    component: SlidoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/users-management/roles/:id',
    name: 'edit-role',
    component: CreateEditRole,
    meta: { requiresAuth: true },
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/poi/:poi_id/create_booking',
    name: 'createManageBooking',
    component: CreateEditBooking,
    meta: { requiresAuth: true },
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/poi/:poi_id/edit_booking/:reservation_id/:appointment_id',
    name: 'editManageBooking',
    component: CreateEditBooking,
    meta: { requiresAuth: true },
  },
  {
    path: '/building/:building_id/:category_id/pois-list',
    name: 'PoisListView',
    component: PoisListView,
    meta: { requiresAuth: true },
  },
  {
    path: '/building/:building_id/:category_id/:poi_id',
    name: 'PoiDetails',
    component: PoiDetails,
    meta: { requiresAuth: true },
  },

  {
    path: '/menu/locks',
    name: 'LocksView',
    component: LocksView,
    meta: { requiresAuth: true },
  },
  {
    path: '/infocenter',
    name: 'InfoCenterView',
    component: InfoCenterView,
    meta: { requiresAuth: true },
  },
  {
    path: '/create-edit-info-item',
    name: 'CreateEditInfoItem',
    component: CreateEditInfoItem,
    meta: { requiresAuth: true },
  },
  {
    path: '/create-edit-info-item/:id',
    name: 'EditInfoItem',
    component: CreateEditInfoItem,
    meta: { requiresAuth: true },
  },

  {
    path: '/menu',
    name: 'MenuView',
    component: MenuView,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/feedback',
    name: 'FeedbackForm',
    component: FeedbackForm,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/feedbacks/admin',
    name: 'AdminFeedbacks',
    component: AdminFeedbacks,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/room-control',
    component: RoomControlPois,
    name: 'RoomControlPois',
    meta: { requiresAuth: true },
  },
  {
    path: '/menu/room-control/:poi_id',
    component: RoomControl,
    name: 'RoomControl',
    meta: { requiresAuth: true },
  },

  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { public: true },
  },
  {
    path: '/oauth2/code/callback',
    name: 'auth-callback',
    component: AuthCallback,
    meta: { public: true },
  },
  {
    path: '/unauthorized-email',
    name: 'unauthorized',
    component: Unauthorized,
    meta: { public: true },
  },
  {
    path: '/logged-out',
    name: 'logged-out',
    component: LoggedOut,
    meta: { public: true },
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: NoPermissionView,
    meta: { requiresAuth: true },
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    meta: { requiresAuth: true },
  },
  {
    path: '/functions',
    name: 'Functions',
    component: Functions,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/manage-incidents',
    name: 'AdminIncidentsManagement',
    component: AdminIncidentsManagement,
    meta: { requiresAuth: true },
  },
  // Define other routes as needed...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Fixed typo here
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authService = useOAuthService()
  const loggedIn = await authService.isLoggedIn()

  // If route is public or user is logged in, continue
  if (to.meta.public || loggedIn) {
    next()
    return
  }

  // Store the route user wanted to access
  if (!loggedIn && to.name !== 'auth-callback') {
    // Store the full path in local storage or a global state
    localStorage.setItem('redirectAfterLogin', to.fullPath)
    next({ name: 'Login' })
    return
  }

  next()
})

export default router

/**
 * This file defines the routes for the application using Vue Router. It includes various views and components that users can navigate to within the app. Each route is associated with a specific path and component, and many routes require authentication to access.
 *
 * HomeView: This is the main landing page of the application. Users are redirected here after logging in. It provides an overview of the app's features and functionalities.
 * URLs:
 * - http://localhost:8080/home
 * - http://pia.qa.pinestack.eu/home
 * - http://pia.staging.pinestack.io/home
 *
 * AuthCallback: This component handles the OAuth2 authentication callback. It processes the authentication response and redirects users accordingly.
 * URLs:
 * - http://localhost:8080/oauth2/code/callback
 * - http://pia.qa.pinestack.eu/oauth2/code/callback
 * - http://pia.staging.pinestack.io/oauth2/code/callback
 *
 * LoggedOut: This view informs users that they have been logged out of the application.
 * URLs:
 * - http://localhost:8080/logged-out
 * - http://pia.qa.pinestack.eu/logged-out
 * - http://pia.staging.pinestack.io/logged-out
 *
 * Unauthorized: This view is displayed when users attempt to access a resource they do not have permission to view.
 * URLs:
 * - http://localhost:8080/unauthorized-email
 * - http://pia.qa.pinestack.eu/unauthorized-email
 * - http://pia.staging.pinestack.io/unauthorized-email
 *
 * NoPermissionView: Similar to Unauthorized, this view is shown when users lack the necessary permissions to access a specific part of the app.
 * URLs:
 * - http://localhost:8080/no-permission
 * - http://pia.qa.pinestack.eu/no-permission
 * - http://pia.staging.pinestack.io/no-permission
 *
 * LoginPage: This is the login page where users can enter their credentials to access the app. If the login is successful, users are redirected to the HomeView.
 * URLs:
 * - http://localhost:8080/login
 * - http://pia.qa.pinestack.eu/login
 * - http://pia.staging.pinestack.io/login
 *
 * BuildingView: This view provides information about the building, including various facilities and services available.
 * URLs:
 * - http://localhost:8080/building
 * - http://pia.qa.pinestack.eu/building
 * - http://pia.staging.pinestack.io/building
 *
 * BookingView: This view allows users to manage their bookings. It includes child routes for viewing points of interest (POIs) and user bookings.
 * URLs:
 * - http://localhost:8080/booking
 * - http://pia.qa.pinestack.eu/booking
 * - http://pia.staging.pinestack.io/booking
 *
 * LocksView: This view manages the locks within the building, allowing users to control access to various areas.
 * URLs:
 * - http://localhost:8080/menu/locks
 * - http://pia.qa.pinestack.eu/menu/locks
 * - http://pia.staging.pinestack.io/menu/locks
 *
 * MenuView: This view serves as the main menu for the application, providing access to different sections such as analytics, profile, incidents, and more.
 * URLs:
 * - http://localhost:8080/menu
 * - http://pia.qa.pinestack.eu/menu
 * - http://pia.staging.pinestack.io/menu
 *
 * PoisListView: This view lists all points of interest within a specific building and category.
 * URLs:
 * - http://localhost:8080/building/:building_id/:category_id/pois-list
 * - http://pia.qa.pinestack.eu/building/:building_id/:category_id/pois-list
 * - http://pia.staging.pinestack.io/building/:building_id/:category_id/pois-list
 *
 * InfoCenterView: This view provides users with information and resources related to the building and its services.
 * URLs:
 * - http://localhost:8080/infocenter
 * - http://pia.qa.pinestack.eu/infocenter
 * - http://pia.staging.pinestack.io/infocenter
 *
 * UserBookings: This component displays the bookings made by the user.
 * URLs:
 * - http://localhost:8080/booking/user-bookings
 * - http://pia.qa.pinestack.eu/booking/user-bookings
 * - http://pia.staging.pinestack.io/booking/user-bookings
 *
 * RoomControlPois: This component allows users to control various aspects of a room, such as lighting and temperature.
 * URLs:
 * - http://localhost:8080/menu/room-control
 * - http://pia.qa.pinestack.eu/menu/room-control
 * - http://pia.staging.pinestack.io/menu/room-control
 *
 * CreateEditBooking: This component is used for creating and editing bookings. It includes input fields for booking details and buttons to save or cancel the booking.
 * URLs:
 * - http://localhost:8080/building/:building_id/:category_id/poi/:poi_id/create_booking
 * - http://pia.qa.pinestack.eu/building/:building_id/:category_id/poi/:poi_id/create_booking
 * - http://pia.staging.pinestack.io/building/:building_id/:category_id/poi/:poi_id/create_booking
 *
 * MarketingView: This view provides information about marketing activities and promotions.
 * URLs:
 * - http://localhost:8080/marketing
 * - http://pia.qa.pinestack.eu/marketing
 * - http://pia.staging.pinestack.io/marketing
 *
 * RoomControl: This component provides detailed control over a specific room, allowing users to adjust settings and preferences.
 * URLs:
 * - http://localhost:8080/menu/room-control/:poi_id
 * - http://pia.qa.pinestack.eu/menu/room-control/:poi_id
 * - http://pia.staging.pinestack.io/menu/room-control/:poi_id
 *
 * Vouchers: This component manages vouchers that users can redeem for various services.
 * URLs:
 * - http://localhost:8080/vouchers
 * - http://pia.qa.pinestack.eu/vouchers
 * - http://pia.staging.pinestack.io/vouchers
 *
 * ChatUsersList: This component lists all users available for chat.
 * URLs:
 * - http://localhost:8080/users
 * - http://pia.qa.pinestack.eu/users
 * - http://pia.staging.pinestack.io/users
 *
 * Chat: This component provides a chat interface for users to communicate with each other.
 * URLs:
 * - http://localhost:8080/users/:email
 * - http://pia.qa.pinestack.eu/users/:email
 * - http://pia.staging.pinestack.io/users/:email
 *
 * Elevator: This component manages the elevator system within the building.
 * URLs:
 * - http://localhost:8080/elevator
 * - http://pia.qa.pinestack.eu/elevator
 * - http://pia.staging.pinestack.io/elevator
 *
 * AnalyticsList: This view lists various analytics available within the app.
 * URLs:
 * - http://localhost:8080/menu/analytics
 * - http://pia.qa.pinestack.eu/menu/analytics
 * - http://pia.staging.pinestack.io/menu/analytics
 *
 * OfficeAnalytics: This view provides analytics related to office usage and occupancy.
 * URLs:
 * - http://localhost:8080/menu/analytics/officedays
 * - http://pia.qa.pinestack.eu/menu/analytics/officedays
 * - http://pia.staging.pinestack.io/menu/analytics/officedays
 *
 * OccupancyAnalytics: This view provides analytics related to the occupancy of different areas within the building.
 * URLs:
 * - http://localhost:8080/menu/analytics/occupancy
 * - http://pia.qa.pinestack.eu/menu/analytics/occupancy
 * - http://pia.staging.pinestack.io/menu/analytics/occupancy
 *
 * BookingsManagement: This view allows administrators to manage all bookings within the building.
 * URLs:
 * - http://localhost:8080/menu/booking-management
 * - http://pia.qa.pinestack.eu/menu/booking-management
 * - http://pia.staging.pinestack.io/menu/booking-management
 *
 * UsersManagement: This view allows administrators to manage users, including creating, editing, and deleting user accounts.
 * URLs:
 * - http://localhost:8080/menu/users-management
 * - http://pia.qa.pinestack.eu/menu/users-management
 * - http://pia.staging.pinestack.io/menu/users-management
 *
 * UsersList: This component lists all users within the system.
 * URLs:
 * - http://localhost:8080/menu/users-management/users
 * - http://pia.qa.pinestack.eu/menu/users-management/users
 * - http://pia.staging.pinestack.io/menu/users-management/users
 *
 * GroupsList: This component lists all user groups within the system.
 * URLs:
 * - http://localhost:8080/menu/users-management/groups
 * - http://pia.qa.pinestack.eu/menu/users-management/groups
 * - http://pia.staging.pinestack.io/menu/users-management/groups
 *
 * RolesList: This component lists all roles within the system.
 * URLs:
 * - http://localhost:8080/menu/users-management/roles
 * - http://pia.qa.pinestack.eu/menu/users-management/roles
 * - http://pia.staging.pinestack.io/menu/users-management/roles
 *
 * PoiDetails: This component provides detailed information about a specific point of interest.
 * URLs:
 * - http://localhost:8080/building/:building_id/:category_id/:poi_id
 * - http://pia.qa.pinestack.eu/building/:building_id/:category_id/:poi_id
 * - http://pia.staging.pinestack.io/building/:building_id/:category_id/:poi_id
 *
 * FeedbackForm: This component allows users to submit feedback about the app or building services.
 * URLs:
 * - http://localhost:8080/menu/feedback
 * - http://pia.qa.pinestack.eu/menu/feedback
 * - http://pia.staging.pinestack.io/menu/feedback
 *
 * UserProfile: This view displays the user's profile information and allows them to edit their details.
 * URLs:
 * - http://localhost:8080/menu/profile
 * - http://pia.qa.pinestack.eu/menu/profile
 * - http://pia.staging.pinestack.io/menu/profile
 *
 * Incidents: This component manages incidents reported within the building.
 * URLs:
 * - http://localhost:8080/menu/incidents
 * - http://pia.qa.pinestack.eu/menu/incidents
 * - http://pia.staging.pinestack.io/menu/incidents
 *
 * CreateEditInfoItem: This component is used for creating and editing information items within the InfoCenter.
 * URLs:
 * - http://localhost:8080/create-edit-info-item
 * - http://pia.qa.pinestack.eu/create-edit-info-item
 * - http://pia.staging.pinestack.io/create-edit-info-item
 *
 * Functions: This component provides various functions and utilities for users.
 * URLs:
 * - http://localhost:8080/functions
 * - http://pia.qa.pinestack.eu/functions
 * - http://pia.staging.pinestack.io/functions
 *
 * AdminIncidentsManagement: This view allows administrators to manage all incidents reported within the building.
 * URLs:
 * - http://localhost:8080/admin/manage-incidents
 * - http://pia.qa.pinestack.eu/admin/manage-incidents
 * - http://pia.staging.pinestack.io/admin/manage-incidents
 *
 * CreateEditUser: This component is used for creating and editing user accounts.
 * URLs:
 * - http://localhost:8080/menu/users-management/users/new
 * - http://pia.qa.pinestack.eu/menu/users-management/users/new
 * - http://pia.staging.pinestack.io/menu/users-management/users/new
 *
 * CreateEditGroup: This component is used for creating and editing user groups.
 * URLs:
 * - http://localhost:8080/menu/users-management/groups/new
 * - http://pia.qa.pinestack.eu/menu/users-management/groups/new
 * - http://pia.staging.pinestack.io/menu/users-management/groups/new
 *
 * CreateEditRole: This component is used for creating and editing user roles.
 * URLs:
 * - http://localhost:8080/menu/users-management/roles/new
 * - http://pia.qa.pinestack.eu/menu/users-management/roles/new
 * - http://pia.staging.pinestack.io/menu/users-management/roles/new
 *
 * SlidoView: This component integrates with Slido for interactive Q&A and polling.
 * URLs:
 * - http://localhost:8080/menu/slido
 * - http://pia.qa.pinestack.eu/menu/slido
 * - http://pia.staging.pinestack.io/menu/slido
 *
 * AdminFeedbacks: This component allows administrators to manage all feedback submitted by users.
 * URLs:
 * - http://localhost:8080/menu/feedbacks/admin
 * - http://pia.qa.pinestack.eu/menu/feedbacks/admin
 * - http://pia.staging.pinestack.io/menu/feedbacks/admin
 */
