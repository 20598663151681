<template>
  <div class="infocenter">
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Communication' }}
      </v-toolbar-title>
      <v-btn icon>
        <v-icon @click="refresh" color="#007BFF">mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <Loader :loading="showLoader" />

    <div v-if="!isMobileView">
      <InfoCenterDesktop v-if="!showLoader" :newsItems="newsItems" :teamMembers="teamMembers"
        :upcomingEvents="upcomingEvents" :userProfile="userOwnProfile" :rootProfile="rootProfile"
        :itemClicked="openDialog" :editItem="editItem" :deleteItem="deleteItem" :isEditModeParent="isEditMode"
        @update:isEditModeParent="updateEditMode" />
    </div>
    <div v-else>
      <InfoCenterMobile v-if="!showLoader" :newsItems="newsItems" :teamMembers="teamMembers"
        :upcomingEvents="upcomingEvents" :userProfile="userOwnProfile" :rootProfile="rootProfile"
        :itemClicked="openDialog" :editItem="editItem" :deleteItem="deleteItem" :isEditModeParent="isEditMode"
        @update:isEditModeParent="updateEditMode" />
    </div>
    <!-- Dialog Box for Detailed View -->
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="elegant-dialog-card">
        <!-- Dialog Toolbar -->
        <v-toolbar flat class="dialog-toolbar">
          <!-- <v-toolbar-title class="headline">{{ selectedItem?.title }}</v-toolbar-title> -->
          <!-- <v-chip class="category-chip">{{ selectedItem?.category }}</v-chip> -->
          <v-chip variant="elevated" class="ml-5" color="black" density="comfortable" pill>
            {{ selectedItem?.category }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <!-- Main Container with Grid Layout -->
          <v-container fluid>
            <!-- Category Section -->
            <v-row class="mb-2">
              <v-col cols="12">
                <header>
                  <h1>{{ selectedItem?.title }}</h1>
                </header>
              </v-col>
            </v-row>

            <!-- Title Image Section -->
            <v-row v-if="selectedItem?.image" class="mb-3 justify-center">
              <v-col cols="12" lg="6" md="5" sm="6">
                <v-card flat class="title-image-card">
                  <v-img :src="selectedItem.image" class="title-image" max-width="600px" />
                </v-card>
              </v-col>
            </v-row>

            <!-- Short Text Description Section -->
            <v-row class="mb-3">
              <v-col cols="12">
                <v-card flat class="description-card">
                  <div v-html="selectedItem?.text" class="item-text"></div>
                </v-card>
              </v-col>
            </v-row>

            <!-- Additional Images Section -->
            <v-row v-if="selectedItem?.additionalImages && selectedItem.additionalImages.length > 0" class="mb-4">
              <v-col cols="12">
                <v-card flat class="additional-images-card">
                  <v-card-title>Additional Images</v-card-title>
                  <v-row class="images-grid" dense>
                    <v-col v-for="(image, index) in selectedItem.additionalImages" :key="index" cols="6" sm="4" md="3">
                      <div class="additional-image-container" @click="openImageModal(image)"
                        @touchstart="showHoverEffect(index)" @touchend="hideHoverEffect(index)">
                        <v-img :src="image" class="additional-image" />
                        <div :class="['additional-image-hover-text', { visible: activeIndex === index }]">
                          <v-icon left>mdi-eye</v-icon>
                          View Image
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!-- Files Section -->
            <v-row v-if="selectedItem?.files && selectedItem.files.length > 0" class="mb-3">
              <v-col cols="12">
                <v-card flat class="files-card">
                  <v-card-title>Files</v-card-title>
                  <v-row dense>
                    <v-col v-for="(file, index) in selectedItem.files" :key="index" cols="12" sm="6" md="4">
                      <v-card class="mx-auto" prepend-icon="mdi-file" variant="elevated" elevation="5">
                        <template v-slot:title>
                          <span class="font-weight-black" style="font-size: 15px; white-space: break-spaces">{{
                            file.fileName }}</span>
                        </template>

                        <v-card-actions class="light pt-4" style="background: aliceblue">
                          <v-btn class="elegant-button" @click="viewFile(file)">
                            <v-icon left>mdi-eye</v-icon>
                            View
                          </v-btn>
                          <v-btn class="elegant-button" @click="downloadFile(file)">
                            <v-icon left>mdi-download</v-icon>
                            Download
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!-- PDF Viewer Modal -->
            <v-row v-if="pdfDialog" class="mb-4">
              <v-col cols="12">
                <v-card flat class="pdf-viewer-card">
                  <PDF v-if="pdfSrc" :src="pdfSrc" />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <div v-if="imageModalOpen" class="custom-image-modal">
        <div class="custom-image-modal-content">
          <button class="close-button" @click="closeImageModal">&times;</button>
          <img :src="currentImageSrc" alt="Enlarged Image" class="modal-image" />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding'
import Loader from '@/components/general/Loader.vue'
import defaultInfoItem from '@/assets/Branding/Pia/defaultInfoItem.png'
import {
  getInfoCategories,
  getInfoItems,
  getFile,
  getDataFromBlob,
  getUserProfiles,
  getFileInfo,
  isStaingOrQa,
  removeStoredItem,
  deleteInfoItem,
} from '@/controllers/BaseController'
import { getUsers } from '@/controllers/BackboneAPI'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import InfoCenterDesktop from '@/components/general/ChildComponents/InfoCenterDesktop.vue'
import InfoCenterMobile from '@/components/general/ChildComponents/InfoCenterMobile.vue'
import lodash, { add } from 'lodash'
import PDF from 'pdf-vue3'

import { useOAuthService } from '@/services/OAuthService'

export default defineComponent({
  components: {
    Loader,
    InfoCenterDesktop,
    InfoCenterMobile,
    PDF,
  },
  setup() {
    const logo = ref('')
    const router = useRouter()
    const OAuthService = useOAuthService()
    let userOwnProfile = ref({})
    const isMobileView = ref(window.innerWidth <= 960)
    const allInfoItems = ref([])
    const isEditMode = ref(false)
    const getInitials = name => {
      if (!name) return ''
      const nameParts = name.split(' ')
      if (nameParts.length === 1) {
        return nameParts[0].charAt(0).toUpperCase()
      }
      return nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }
    const teamMembers = ref([])
    const upcomingEvents = ref([])
    const newsItems = ref([])
    const showLoader = ref(false)
    const rootProfile = ref({})
    const showDialog = ref(false)
    const selectedItem = ref(null)
    const pdfDialog = ref(false)
    const pdfSrc = ref('')
    const infoCategories = ref([])
    const imageModalOpen = ref(false)
    const currentImageSrc = ref('')
    const activeIndex = ref(null)

    const handleResize = () => {
      isMobileView.value = window.innerWidth <= 960
    }

    const editItem = item => {
      item = JSON.parse(JSON.stringify(item))
      if (!item?.id) {
        let INFOITEMS = JSON.parse(JSON.stringify(allInfoItems.value))
        item = lodash.find(INFOITEMS, { title: item.title })
      }
      router.push({ name: 'EditInfoItem', params: { id: item.id } }).catch(err => console.error(err))
    }

    const deleteItem = async item => {
      const router = useRouter()

      try {
        item = JSON.parse(JSON.stringify(item))
        if (!item?.id) {
          let INFOITEMS = JSON.parse(JSON.stringify(allInfoItems.value))
          item = lodash.find(INFOITEMS, { title: item.title })
        }

        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Do you really want to delete this item? This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          confirmButtonColor: '#90EE90', // Light green
          cancelButtonColor: '#FF7F7F', // Light red
          cancelButtonText: 'Cancel',
          reverseButtons: true,
        })

        if (result.isConfirmed) {
          // Show Swal loader after confirmation
          Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we delete the item.',
            icon: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading()
            },
          })

          // Simulate the delete action and show loader
          showLoader.value = true
          await deleteInfoItem(item.id)

          // On success, show success Swal and countdown before refresh
          Swal.fire({
            title: 'Deleted!',
            text: 'The item has been deleted successfully.',
            icon: 'success',
            timer: 2000, // Show success message for 2 seconds
            showConfirmButton: false,
          }).then(async () => {
            // Countdown for refresh
            let countdown = 3
            const countdownSwal = Swal.fire({
              title: 'Refreshing in 3 seconds...',
              text: 'Refreshing in 3 seconds...',
              icon: 'info',
              timer: 3000,
              allowOutsideClick: false,
              showConfirmButton: false,
              willOpen: () => {
                const interval = setInterval(() => {
                  countdown--
                  Swal.update({
                    text: `Refreshing in ${countdown} seconds...`,
                  })
                  if (countdown === 0) clearInterval(interval)
                }, 1000)
              },
            })

            // Wait until the Swal timer finishes
            await countdownSwal

            // Clear stored items and refresh page using router
            await removeStoredItem('infoItems')
            await removeStoredItem('infoCategories')

            // Refresh the page using Vue Router
            refreshPage()
          })
        }
      } catch (error) {
        // On failure, show error Swal with 2 seconds timeout
        console.log(error)
        Swal.fire({
          title: 'Failed!',
          text: 'Failed to delete the item. Please try again.',
          icon: 'error',
          timer: 2000, // Show error message for 2 seconds
          showConfirmButton: false,
        })
      } finally {
        showLoader.value = false // Hide loader
      }
    }

    const refreshPage = () => {
      router.go(0)
    }

    window.addEventListener('resize', handleResize)

    const refresh = async () => {
      try {
        showLoader.value = true
        await removeStoredItem('infoItems')
        await removeStoredItem('infoCategories')
      } catch (error) {
        console.error(error)
      } finally {
        location.reload()
      }
    }

    onMounted(async () => {
      try {
        showLoader.value = true

        // Fetch required data
        const profile = await OAuthService.getUserProfile()
        let isPia = await isStaingOrQa()

        const logoImage = await getToolbarLogoImage()
        let apiInfoItems = await getInfoItems()

        const filteredApiInfoItems = lodash.filter(apiInfoItems, item => {
          const creationTime = moment(item.creationTime)
          return moment().diff(creationTime, 'days') <= 120
        })
        apiInfoItems = filteredApiInfoItems
        allInfoItems.value = apiInfoItems
        const apiInfoCategories = await getInfoCategories()
        const userProfiles = await getUserProfiles()
        const users = await getUsers()
        rootProfile.value = profile
        infoCategories.value = apiInfoCategories

        // Assign logo
        logo.value = logoImage

        // Filter and categorize info items
        const eventItems = apiInfoCategories
          .filter(category => category?.name?.toLowerCase() === 'events')
          .flatMap(category => apiInfoItems.filter(item => item.infoCategory.id === category.id))
        const newsAnnounceItems = ['news', 'announcement'].flatMap(name =>
          apiInfoCategories
            .filter(category => category?.name?.toLowerCase() === name)
            .flatMap(category => apiInfoItems.filter(item => item.infoCategory.id === category.id))
        )

        // Format event items
        upcomingEvents.value = eventItems.map(event => ({
          title: event.title,
          description: event.text.replace(/<\/?[^>]+(>|$)/g, ''),
          date: event?.shortText,
        }))

        // Format news items
        newsItems.value = []
        console.log(newsAnnounceItems)
        for (let item of newsAnnounceItems) {
          const titleImageId = item?.files?.entities.find(file => file.type === 'titleimage')
          const titleImage = titleImageId?.id ? await getDataFromBlob(await getFile(titleImageId.id)) : null
          newsItems.value.push({ ...item, image: titleImage ? titleImage : defaultInfoItem, description: item.shortText })
        }

        // Filter user profiles
        const filteredNames = ['khizar', 'anny', 'maximilian', 'wilfried', 'graphic', 'qa']
        const emailFilter = email => filteredNames.some(name => email?.toLowerCase().includes(name))

        userOwnProfile.value = userProfiles.find(item => item.email === profile.email)

        const filteredUsers = users.filter(
          user =>
            emailFilter(user.email) &&
            `${user.firstName} ${user.lastName}`.toLowerCase() !== `${profile.firstName} ${profile.lastName}`.toLowerCase() &&
            !user.email?.toLowerCase()?.includes('smkhizar') &&
            !user.email?.toLowerCase()?.includes('meri')
        )

        // Populate team members
        const statuses = ['Remote', 'Dayoff', 'InOffice']
        teamMembers.value = []
        for (let user of filteredUsers) {
          const name = `${user.firstName} ${user.lastName}` // Combine firstName and lastName
          const position = user.description || null // Use description for position, default to null if not available

          // Fetch image from userProfiles
          const userProfile = userProfiles.find(profile => profile.email === user.email)
          const imageId = userProfile?.files?.entities.find(file => file.type === 'profileimage')
          const image = imageId?.id ? await getDataFromBlob(await getFile(imageId.id)) : null

          teamMembers.value.push({
            name: name,
            position: position,
            status: statuses[Math.floor(Math.random() * statuses.length)], // Randomly select status
            initials: getInitials(name),
            image: image,
            email: user.email,
          })
        }

        showLoader.value = false
      } catch (error) {
        console.error(error)
        showLoader.value = false
      }
    })

    const openDialog = async item => {
      let itemSelected = JSON.parse(JSON.stringify(item))
      let image = lodash.find(itemSelected.files.entities, { type: 'titleimage' })
      let files = lodash.filter(itemSelected.files.entities, { type: 'attachment' })
      let additionalImages = lodash.filter(itemSelected.files.entities, { type: 'image' })

      if (additionalImages?.length > 0) {
        let imageIds = additionalImages.map(image => image.id)
        let detailedImages = []
        for (let imageId of imageIds) {
          let image = await getDataFromBlob(await getFile(imageId))
          detailedImages.push(image)
        }
        itemSelected.additionalImages = detailedImages
      }

      if (files?.length > 0) {
        let fileIds = files.map(file => file.id)
        let detailedFiles = []
        for (let fileId of fileIds) {
          let file = await getFileInfo(fileId)
          file.fileName = file?.fileName?.split('.')[0]
          file.url = await getFile(file.id)
          file.url = await getDataFromBlob(file.url)
          detailedFiles.push(file)
        }
        itemSelected.files = detailedFiles
      }

      itemSelected.image = image ? await getDataFromBlob(await getFile(image.id)) : defaultInfoItem
      let category = infoCategories.value.find(category => category.id === itemSelected.infoCategory.id)?.name
      itemSelected.category = category

      selectedItem.value = itemSelected
      if (!isEditMode.value) {
        showDialog.value = true
      }
    }

    const viewFile = file => {
      pdfDialog.value = false
      pdfSrc.value = null

      setTimeout(() => {
        pdfSrc.value = file.url
        pdfDialog.value = true
      }, 100)
    }

    const downloadFile = file => {
      file = JSON.parse(JSON.stringify(file))
      const link = document.createElement('a')
      link.href = file.url
      link.download = file.fileName.split('.').shift()
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    const onPdfComplete = () => {
      pdfDialog.value = false
    }

    const openImageModal = image => {
      currentImageSrc.value = image
      imageModalOpen.value = true
    }

    const closeImageModal = () => {
      imageModalOpen.value = false
      currentImageSrc.value = ''
    }

    const showHoverEffect = index => {
      activeIndex.value = index
    }

    const hideHoverEffect = () => {
      activeIndex.value = null
    }
    const updateEditMode = newEditMode => {
      isEditMode.value = newEditMode
    }

    return {
      showLoader,
      logo,
      isMobileView,
      upcomingEvents,
      refresh,
      newsItems,
      teamMembers,
      rootProfile,
      userOwnProfile,
      showDialog,
      selectedItem,
      pdfDialog,
      router,
      pdfSrc,
      openDialog,
      viewFile,
      downloadFile,
      onPdfComplete,
      editItem,
      deleteItem,
      infoCategories,
      openImageModal,
      closeImageModal,
      imageModalOpen,
      currentImageSrc,
      showHoverEffect,
      hideHoverEffect,
      activeIndex,
      isEditMode,
      updateEditMode,
      allInfoItems,
    }
  },
})
</script>

<style scoped>
/* Custom Toolbar Styles */
.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.toolbar-icon {
  height: 30px;
  align-self: center;
}

.infocenter {
  padding: 1rem;
}

/* Dialog Box Styles */
.elegant-dialog-card {
  background-color: rgba(255, 255, 255, 1.11);
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.dialog-toolbar {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.category-chip {
  font-weight: bold;
  background-color: #007bff;
  color: white;
  padding: 8px;
  border-radius: 16px;
  font-size: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.title-image-card {
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
}

.title-image {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.description-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.additional-images-card {
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.images-grid {
  gap: 16px;
  flex-wrap: wrap;
}

/* Additional Images Container with Hover Effect */
.additional-image-container {
  position: relative;
  overflow: hidden;
}

.additional-image {
  transition: 0.5s ease;
  display: block;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}

.additional-image-hover-text {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
}

/* Hover effect for desktop */
.additional-image-container:hover .additional-image {
  opacity: 0.3;
}

.additional-image-container:hover .additional-image-hover-text,
.additional-image-hover-text.visible {
  opacity: 1;
}

.custom-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.custom-image-modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.files-card {
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.elegant-chip {
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  margin: 0.5rem;
}

.elegant-chip:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #e9e9e9;
}

.pdf-modal {
  width: 100%;
  max-height: 500px;
  overflow: auto;
  margin-top: 1rem;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
}

.pdf-viewer-card {
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

header h1 {
  font-size: 55px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #553c9a, #ee4b2b);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@media (max-width: 640px) {
  .item-text {
    font-size: small;
  }

  .custom-toolbar-title {
    font-size: large;
  }

  header h1 {
    font-size: 35px;
  }
}

@media (max-width: 400px) {
  .item-text {
    font-size: smaller;
  }

  header h1 {
    font-size: 25px;
  }
}
</style>

<!-- InfoCenterView Component
This component provides a comprehensive information center for users, offering a variety of functionalities and interactive elements. 

At the top, there is a toolbar with a logo, a title "Communication", and a refresh button. The refresh button allows users to reload the content of the information center.

Below the toolbar, a Loader component is displayed when data is being fetched or processed, providing visual feedback to users that an operation is in progress.

The main content of the InfoCenterView is divided into two sections: one for desktop view and one for mobile view. The component dynamically switches between these views based on the screen width.

In the desktop view, the InfoCenterDesktop component is used, while in the mobile view, the InfoCenterMobile component is used. Both components display news items, team members, upcoming events, and user profiles. Users can interact with these items by clicking on them to open a detailed view dialog.

The detailed view dialog is a modal that provides an in-depth look at a selected item. It includes a toolbar with the item's category and a close button. The dialog displays the item's title, an optional title image, a description, additional images, and files. Users can view or download files directly from the dialog. If the item includes a PDF file, it can be viewed within the dialog using the PDF viewer.

The component also includes functionality for editing and deleting items. When an item is edited, the user is redirected to an edit page. When an item is deleted, a confirmation dialog is shown using Swal. If the user confirms the deletion, a loading indicator is displayed, and the item is deleted. Upon successful deletion, a success message is shown, and the page is refreshed after a short countdown. If the deletion fails, an error message is displayed.

Users can also open an image modal to view additional images in a larger format. The image modal includes a close button to return to the previous view.

Overall, the InfoCenterView component provides a rich and interactive experience for users, allowing them to access and manage information efficiently. -->
