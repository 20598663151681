<template>
  <div class="home-view">
    <LandingPageDesktopVue v-if="!isMobileView" />
    <LandingPageMobileVue v-else />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import LandingPageDesktopVue from '@/components/general/ChildComponents/LandingPageDesktop.vue';
import LandingPageMobileVue from '@/components/general/ChildComponents/LandingPageMobile.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    LandingPageDesktopVue,
    LandingPageMobileVue,
  },
  setup() {
    const isMobileView = ref(window.innerWidth <= 480);

    const handleResize = () => {
      isMobileView.value = window.innerWidth <= 480
    };


    onMounted(() => {
      handleResize(); // Ensure it's updated correctly on mount
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    });

    return {
      isMobileView,
    };
  },
});
</script>

<style scoped>
.home-view {
  /* display: flex; */
  flex-direction: column;
  /* min-height: 100vh;
  background: linear-gradient(135deg, #eef2f6 0%, #ffffff 100%); */
}
</style>
