import { ref } from 'vue'
import { Browser } from '@capacitor/browser'
import { loadConfig } from '@/configLoader' // Ensure this path is correct
import { Device } from '@capacitor/device'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import { Preferences } from '@capacitor/preferences'

import { parseJwt, isTokenExpired } from './utils'

const token = ref(null)
const isWebPlatform = platform => platform === 'web'

async function getStorage() {
  const { platform } = await Device.getInfo()
  return platform === 'web' ? window.localStorage : SecureStoragePlugin
}

async function getToken() {
  const platform = (await Device.getInfo()).platform
  const key = 'pinestack.token'

  if (isWebPlatform(platform)) {
    return window.localStorage.getItem(key)
  } else {
    const { value } = await Preferences.get({ key })
    return value
  }
}

export async function isIos() {
  const { operatingSystem } = await Device.getInfo()
  return operatingSystem === 'ios'
}
export async function isAndroid() {
  const { operatingSystem } = await Device.getInfo()
  return operatingSystem === 'android'
}

async function setRedirectUrl(url) {
  const { platform } = await Device.getInfo()
  const key = 'redirectAfterLogin'

  if (isWebPlatform(platform)) {
    window.localStorage.setItem(key, url)
  } else {
    await Preferences.set({ key, value: url })
  }
}

async function setToken(tokenValue) {
  const platform = (await Device.getInfo()).platform
  const key = 'pinestack.token'

  if (isWebPlatform(platform)) {
    window.localStorage.setItem(key, tokenValue)
  } else {
    await Preferences.set({ key, value: tokenValue })
  }

  token.value = tokenValue
}

async function removeToken() {
  const platform = (await Device.getInfo()).platform
  const key = 'pinestack.token'
  if (isWebPlatform(platform)) {
    window.localStorage.removeItem(key)
  } else {
    await Preferences.remove({ key })
  }
  token.value = null
}

async function getRedirectUrl() {
  const { platform } = await Device.getInfo()
  const key = 'redirectAfterLogin'

  if (isWebPlatform(platform)) {
    return window.localStorage.getItem(key)
  } else {
    const { value } = await Preferences.get({ key })
    return value
  }
}

async function authenticate() {
  try {
    const config = await loadConfig()
    const { platform } = await Device.getInfo()

    // Store the intended redirect URL before authentication
    const redirectAfterLogin = (await getRedirectUrl()) || '/'
    await setRedirectUrl(redirectAfterLogin)

    const callbackUri = platform === 'web' && window.location.href?.includes('localhost') ? config.localRedirectUri : config.redirectUri
    const authUrl = `${config.authorizationUri}?client_id=${encodeURIComponent(
      config.clientId
    )}&redirect_uri=${callbackUri}&response_type=token&scope=email profile`
    let isIOS = await isIos()
    if (isWebPlatform(platform) && !isIOS) {
      window.open(authUrl, '_self')
    } else if (isIOS) {
      const { InAppBrowser } = await import('@capgo/inappbrowser')
      const options = {
        url: authUrl,
        showArrow: false,
      }

      await InAppBrowser.openWebView(options)
      InAppBrowser.addListener('urlChangeEvent', async info => {
        if (info.url.includes('access_token')) {
          InAppBrowser.close()
          const tokenValue = info.url.split('access_token=')[1].split('&')[0]
          await setToken(tokenValue)
          const redirectAfterLogin = (await getRedirectUrl()) || '/'
          await setRedirectUrl('') // Clear the redirect URL
          window.location.href = redirectAfterLogin
        }
      })
    } else {
      const { InAppBrowser } = await import('@capgo/inappbrowser')
      const options = {
        url: authUrl,
        closeButtonColor: '#ffffff',
        activeNativeNavigationForWebview: true,
        disableGoBackOnNativeApplication: false,
        isPresentAfterPageLoad: false,
        isAnimated: false,
        showReloadButton: false,
        visibleTitle: false,
        toolbarColor: 'white',
        showArrow: false,
      }

      await InAppBrowser.openWebView(options)
      InAppBrowser.addListener('urlChangeEvent', async info => {
        if (info.url.includes('access_token')) {
          InAppBrowser.close()
          const tokenValue = info.url.split('access_token=')[1].split('&')[0]
          await setToken(tokenValue)
          const redirectAfterLogin = (await getRedirectUrl()) || '/'
          await setRedirectUrl('') // Clear the redirect URL
          window.location.href = redirectAfterLogin
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
}
async function getUserProfile() {
  let access_token = await getToken()
  const userProfile = access_token ? parseJwt(access_token) : null
  return userProfile
}

async function performLogout() {
  await removeToken()
}

export function useOAuthService() {
  return {
    authenticate,
    getToken,
    getStorage,
    setToken,
    removeToken,
    isIos,
    performLogout,
    isLoggedIn: async () => !!(await getToken()) && !isTokenExpired(parseJwt(await getToken())),
    logOut: async () => {
      await removeToken()
    },
    getUserProfile,
  }
}
