<template>
  <div class="locks-view">
    <v-toolbar class="custom-toolbar" prominent elevation="5">
      <!-- <img :src="logo" class="toolbar-icon" alt="Logo" /> -->
      <v-btn icon @click="$router.go(-1)" style="background-color: black; color: white; margin-right: 1rem">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">Access Control</v-toolbar-title>
      <button :disabled="showLoader || !isBluetoothEnabled || !isBleClientInitialized || !isMobile" @click="resync"
        class="resync-button" @mouseover="e => (e.currentTarget.style.transform = 'scale(1.05)')"
        @mouseleave="e => (e.currentTarget.style.transform = 'scale(1)')">
        <span style="margin-right: 8px; display: flex; align-items: center">
          <v-icon>mdi-restart</v-icon>
        </span>
        Re-Sync
      </button>
    </v-toolbar>

    <v-container v-if="!isMobile">
      <v-card class="mx-auto beautiful-card" dark>
        <v-card-title class="beautiful-title">This Feature is only available for mobile devices.</v-card-title>
      </v-card>
    </v-container>

    <v-container v-else-if="!isBluetoothEnabled">
      <v-card class="mx-auto beautiful-card" dark elevation="5">
        <v-card-title class="beautiful-title">Bluetooth is not enabled</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="enableBluetooth">Enable Bluetooth</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-container v-else-if="!isBleClientInitialized">
      <v-card class="mx-auto beautiful-card" dark elevation="5">
        <v-card-title class="beautiful-title">BLE Client not started</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="initializeBleClient">Initialize Bluetooth Client</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <div v-else>
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-chip size="large" color="black" variant="outlined" dense>
            <v-icon left>mdi-format-list-bulleted</v-icon>
            Locks: {{ locks.length }}
          </v-chip>

          <v-chip size="large" color="black" variant="outlined" dense>
            {{ syncStatus }}
            <v-icon right class="ml-2" :color="syncColor">mdi-checkbox-blank-circle</v-icon>
          </v-chip>
        </div>
      </v-container>
      <!--
      <div v-if="showLoader" class="loader-container">
        <div class="loader"></div>
      </div>
      -->
      <v-container v-if="!showLoader">
        <v-text-field v-model="search" label="Search locks" single-line hide-details class="search-bar"></v-text-field>

        <v-row>
          <v-col cols="12" v-for="lock in filteredLocks" :key="lock.id">
            <div class="lock-item">
              <div class="lock-details">
                <div class="lock-name">{{ lock?.configs?.name }}</div>
                <div class="lock-floor">{{ lock.floor?.name }}</div>
              </div>
              <v-btn icon class="unlock-button" @click="unlock(lock)">
                <v-icon>mdi-key</v-icon>
              </v-btn>
            </div>
            <v-divider :key="`divider-${lock.id}`"></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--
    <div v-if="showLoader" class="overlay"></div>-->
    <div class="loading-container" v-if="showLoader">
      <div class="custom-card">
        <img :src="lock" alt="Loading Lock" class="loading-lock" />
        <div class="loading-text">
          Loading Access Control
          <br />
          <span>Please wait...</span>
        </div>
      </div>
    </div>
    <div v-if="hasFailed" class="failure-card-container">
      <div class="failure-card">
        <div class="failure-card-icon">
          <v-icon size="48">mdi-alert-circle-outline</v-icon>
        </div>
        <div class="failure-card-title">Sorry!</div>
        <div class="failure-card-message">
          {{ failedMessage }}
        </div>
        <v-btn class="text-success" prepend-icon="mdi-restart" size="large" variant="tonal"
          @click="resync">RE-SYNC</v-btn>
      </div>
    </div>
    <!-- <v-snackbar v-model="snackbar" :timeout="3000" location="bottom" :offset-y="true">
      {{ snackbarMessage }}
    </v-snackbar> -->
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted, watch, onBeforeMount } from 'vue'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { BleClient } from '@capacitor-community/bluetooth-le'
import lodash from 'lodash'
import { showToast } from '@/services/utils'
import { Preferences } from '@capacitor/preferences'
// import { BlueId } from '../../plugins/android/blueIdHelperPlugin' // Adjust the import path as necessary

import { isIos, isAndroid } from '@/services/OAuthService'
import { loadConfig } from '@/configLoader'
import { getBottomBarColor } from '@/assets/Branding/branding.js'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import lock from '@/assets/General/lock.gif'
import { getLocks, getHardwareByType, unregisterDeviceAccessControl, syncAccessControlDeviceId } from '@/controllers/BackboneAPI'
import { getFloors, getBuildings } from '@/controllers/BaseController'
import Swal from 'sweetalert2'
import moment from 'moment'
import { Toast } from '@capacitor/toast'

export default defineComponent({
  name: 'LocksView',
  setup() {
    const isMobile = Capacitor.isNativePlatform()
    const search = ref('')
    const logo = ref('')
    const locks = ref([])
    const primaryColor = ref('')
    const showLoader = ref(true)
    const unlockLoader = ref(false)
    const syncStatus = ref('Not Synced')
    const syncColor = ref('red')
    const hasFailed = ref(false)
    const failedMessage = ref('')
    const isBluetoothEnabled = ref(true)
    const isBleClientInitialized = ref(true)
    const deviceIdKey = 'deviceId'
    const secureObjectsKey = 'secureObjects'
    const snackbar = ref(false)
    const snackbarMessage = ref('')
    const ISiOS = ref(false)
    const ISANDROID = ref(false)
    let BlueId = null

    const filteredLocks = computed(() => {
      // If there is no search value, return all locks
      if (!search.value.trim()) {
        return locks.value
      }

      // Convert the search term to lowercase for case-insensitive comparison
      const searchTerm = search.value.toLowerCase().trim()

      return locks.value.filter(lock => {
        // Ensure the lock has a name to compare with the search term
        const lockName = lock?.configs?.name?.toLowerCase() || ''
        const lockFloor = lock?.floor?.name?.toLowerCase() || ''

        // Compare both lock name and floor name with the search term
        return lockName.includes(searchTerm) || lockFloor.includes(searchTerm)
      })
    })

    const showSnackbar = message => {
      snackbarMessage.value = message
      snackbar.value = true
    }

    const checkBluetoothStatus = async () => {
      try {
        await BleClient.initialize()
        const enabled = await BleClient.isEnabled()
        isBluetoothEnabled.value = enabled
      } catch (error) {
        console.error('Error checking Bluetooth status:', error)
        BleClient.openAppSettings()
      }
    }

    const enableBluetooth = async () => {
      try {
        await BleClient.initialize()
        await BleClient.requestEnable()
        isBluetoothEnabled.value = true
      } catch (error) {
        console.error('Error enabling Bluetooth:', error)
        BleClient.openAppSettings()
      }
    }

    const initializeBleClient = async () => {
      try {
        await BleClient.initialize()
        isBleClientInitialized.value = true
      } catch (error) {
        console.error('Error initializing BLE Client:', error)
        BleClient.openAppSettings()
      }
    }

    const isWebPlatform = async () => {
      const { platform } = await Device.getInfo()
      return platform === 'web'
    }

    const storeData = async (key, data) => {
      const stringifiedData = JSON.stringify(data)
      if (await isWebPlatform()) {
        localStorage.setItem(key, stringifiedData)
      } else {
        await Preferences.set({ key, value: stringifiedData })
      }
    }

    const retrieveData = async key => {
      if (await isWebPlatform()) {
        const data = localStorage.getItem(key)
        return data ? JSON.parse(data) : null
      } else {
        const { value } = await Preferences.get({ key })
        return value ? JSON.parse(value) : null
      }
    }

    const removeData = async key => {
      if (await isWebPlatform()) {
        localStorage.removeItem(key)
      } else {
        await Preferences.remove({ key })
      }
    }

    const unlock = async lock => {
      try {
        // Show a custom loading Swal with the lock GIF and elegant styling
        Swal.fire({
          title:
            '<div style="font-size: 24px; font-weight: bold; color: black; border-radius: 25px; padding: 8px 20px; background-color: #f0f0f0;">Unlocking...</div>',
          html: `
        <div style="
          font-size: 18px;
          color: black;
          background: white;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0 4px 10px rgba(0,0,0,0.1);
        ">
          <img src="${require('@/assets/General/lock.gif')}" alt="Loading Lock" style="width: 80px; height: 80px; margin-bottom: 10px;" />
          <p style="margin: 0;">Please wait while we attempt to unlock.</p>
        </div>
      `,
          allowOutsideClick: false,
          showConfirmButton: false,
        })

        // Simulate the unlock API call or any async operation
        let blueIdLock = JSON.parse(JSON.stringify(lock))
        let blueIdObjId = blueIdLock?.blueIdObjectId
        let action = 'tokn'

        let result = await BlueId.runCommand({ id: blueIdObjId, action: action })

        if (result !== undefined) {
          // On success, show a default success icon Swal with elegant text styling
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size: 24px; font-weight: bold;">Access Granted!</div>',
            html: `
          <p style="font-size: 16px; color: black; margin: 0;">Lock opened successfully!</p>
        `,
            showConfirmButton: false,
            timer: 2000, // Disappears after 2 seconds
          })
        } else {
          // On failure, show a default error icon Swal with elegant text styling
          Swal.fire({
            icon: 'error',
            title: '<div style="font-size: 24px; font-weight: bold;">Access Denied!</div>',
            html: `
          <p style="font-size: 16px; color: black; margin: 0;">Please try again.</p>
        `,
            showConfirmButton: false,
            timer: 2000, // Disappears after 2 seconds
          })
        }
      } catch (error) {
        console.error('Failed to unlock:', error)
        // On error, show a default error icon Swal with elegant text styling
        Swal.fire({
          icon: 'error',
          title: '<div style="font-size: 24px; font-weight: bold;">Unlock Failed!</div>',
          html: `
        <p style="font-size: 16px; color: black; margin: 0;">An unexpected error occurred.</p>
      `,
          showConfirmButton: false,
          timer: 2000, // Disappears after 2 seconds
        })
      }
    }

    const loadLocks = async () => {
      try {
        const buildings = await getBuildings()
        const selectedBuilding = buildings[0]?.name || null
        const selectedBuildingObj = buildings.find(building => building.name === selectedBuilding)

        const allLocks = await getHardwareByType(selectedBuildingObj['id'], 'lock')
        const allFloors = await getFloors()

        const floorsMap = new Map(allFloors.map(floor => [floor.id, floor]))

        const filteredLocks = []
        const unSyncedLocks = []

        allLocks.forEach(lock => {
          const blueIdObjectId = lock?.configs?.['blue-id-object-id']
          const floor = floorsMap.get(lock?.location?.floorId)
          filteredLocks.push({
            ...lock,
            floor: floor,
            blueIdObjectId: blueIdObjectId,
          })
        })

        locks.value = lodash.orderBy(
          filteredLocks,
          [
            lock => {
              const name = lodash.get(lock, 'configs.name', '')
              const isNumeric = /^\d/.test(name)
              return (isNumeric ? '1' : '2') + name
            },
          ],
          ['asc']
        )
        return filteredLocks
      } catch (error) {
        console.error('Failed to load locks or floors:', error)
      }
    }

    const resync = async () => {
      showLoader.value = true
      syncStatus.value = 'Resynchronizing...'
      syncColor.value = 'red'

      try {
        let deviceId = null

        showToast({ text: 'Starting resynchronization process...', duration: 2000, type: 'default' })

        // Fetch the deviceId if it exists
        const deviceIdResult = await BlueId.getDeviceId()
        if (deviceIdResult && deviceIdResult.deviceId) {
          deviceId = deviceIdResult.deviceId
          await unregisterDeviceAccessControl(deviceId)
          showToast({ text: 'Device ID unregistered successfully', duration: 2000, type: 'default' })
        }

        // Destroy the existing session
        await BlueId.destroy()
        showToast({ text: 'Session destroyed', duration: 2000, type: 'default' })

        let apiLocks = []

        // Load config and initialize BlueId again
        const config = await loadConfig()
        const apiKey = config.accessControl.apiKey
        const initializeResult = await BlueId.initialize({ apiKey })

        if (initializeResult && initializeResult.deviceId) {
          deviceId = initializeResult.deviceId
          await storeData(deviceIdKey, deviceId)
          showToast({ text: 'Device data stored', duration: 2000, type: 'default' })
          await syncAccessControlDeviceId(deviceId)
          showToast({ text: 'Device access control synced', duration: 2000, type: 'default' })
          apiLocks = await loadLocks()
          showToast({ text: 'Locks loaded', duration: 2000, type: 'default' })
        }

        if (apiLocks.length > 0) {
          const maxAttempts = 20
          let securedObjects = []
          let isSuccess = false

          for (let attempts = 1; attempts <= maxAttempts; attempts++) {
            // Synchronize and fetch secured objects
            const syncResult = await BlueId.synchronize()
            securedObjects = syncResult.securedObjects

            const locksComparisonText = `Attempt ${attempts}: Fetched ${securedObjects.length} secured objects out of ${apiLocks.length}`
            console.log(locksComparisonText)

            showToast({ text: locksComparisonText, duration: 2000, type: 'default' })

            // Check if the condition is satisfied (secured objects >= api locks - 5)
            if (securedObjects.length >= apiLocks.length - 5) {
              syncStatus.value = 'Synced'
              syncColor.value = 'green'
              showToast({ text: 'Resynchronized successfully', duration: 2000, type: 'success' })
              isSuccess = true
              break
            }

            // Wait for 4 seconds before the next attempt
            await new Promise(resolve => setTimeout(resolve, 4000))
          }

          if (!isSuccess) {
            syncStatus.value = 'Failed'
            syncColor.value = 'red'
            showToast({ text: 'Failed to resynchronize after multiple attempts', duration: 3000, type: 'error' })
          }
        } else {
          // If apiLocks is empty, mark it as a success
          syncStatus.value = 'Synced'
          syncColor.value = 'green'
          showToast({ text: 'No locks found to synchronize.', duration: 2000, type: 'success' })
        }
      } catch (error) {
        console.error('Failed to resynchronize:', error)
        syncStatus.value = 'Failed to Resynchronize'
        syncColor.value = 'red'

        // Show a toast message for failure
        showToast({ text: 'Failed to resynchronize', duration: 3000, type: 'error' })
      } finally {
        showLoader.value = false
      }
    }

    const retrySync = async apiLocks => {
      let attempts = 0
      const maxAttempts = 10
      let securedObjects = []

      // Retry logic for synchronization
      for (attempts = 1; attempts <= maxAttempts; attempts++) {
        console.log(`Attempt ${attempts} to sync`)

        let syncResult = await BlueId.synchronize()
        securedObjects = syncResult.securedObjects
        showToast({ text: `Attempt ${attempts}: Secured ${securedObjects.length}, API ${apiLocks.length}`, duration: 2000 })

        // Check if the sync is successful (equal or within 5 locks threshold)
        if (securedObjects.length === apiLocks.length || securedObjects.length >= apiLocks.length - 5) {
          showToast({ text: `Sync successful on attempt ${attempts}`, duration: 2000, type: 'success' })
          return { securedObjects } // Return successfully synced objects
        } else {
          console.log(`Attempt ${attempts} failed. Secured objects: ${securedObjects.length}, API locks: ${apiLocks.length}`)
          showToast({
            text: `Attempt ${attempts} failed: Secured ${securedObjects.length}, API ${apiLocks.length}`,
            duration: 2000, // Adjust the duration as needed
            type: 'error', // Custom styling for error
          })
        }

        // Delay before the next attempt
        await new Promise(resolve => setTimeout(resolve, 4000))
      }

      // If all attempts fail, handle the failure
      hasFailed.value = true
      failedMessage.value = 'Failed to Sync the locks. Please Resync from the top button.'
      showToast({ text: 'Failed to sync after 10 attempts', duration: 3000, type: 'error' })

      return { securedObjects } // Return the last secured objects even if it failed
    }

    onBeforeMount(async () => {
      await BleClient.initialize()
      isBleClientInitialized.value = true
      let bluetoothStatus = await BleClient.isEnabled()
      isBluetoothEnabled.value = bluetoothStatus
    })

    onMounted(async () => {
      showLoader.value = true
      syncStatus.value = 'Syncing...'
      syncColor.value = 'red'

      try {
        if (!isMobile) {
          showLoader.value = false
          logo.value = await getToolbarLogoImage()
          console.log(JSON.parse(JSON.stringify(logo.value)))
          return
        }

        let ios = await isIos()
        let android = await isAndroid()

        if (ios) {
          BlueId = (await import('@/plugins/ios/BlueIdIosHelperPlugin.js')).default
        } else if (android) {
          BlueId = (await import('@/plugins/android/blueIdHelperPlugin.js')).BlueId
        }
        showToast({ text: 'Checking BlueID initialization...', duration: 2000, type: 'default' })

        let { isInitialized } = await BlueId.isInitialized()
        logo.value = await getToolbarLogoImage()

        // Check if BlueId is initialized
        if (!isInitialized) {
          console.log('Initializing BlueId')

          // Add toast for initialization
          showToast({ text: 'Initializing BlueID...', duration: 2000, type: 'default' })

          const config = await loadConfig()
          const apiKey = config.accessControl.apiKey
          let { deviceId } = await BlueId.initialize({ apiKey })

          await syncAccessControlDeviceId(deviceId)
          showToast({ text: 'Device access control synced', duration: 2000, type: 'default' })

          await storeData(deviceIdKey, deviceId)
          showToast({ text: 'Device data stored', duration: 2000, type: 'default' })

          // Sync after initialization
          let apiLocks = await loadLocks()
          let { securedObjects } = await retrySync(apiLocks)

          // Complete the process
          showLoader.value = false
          syncStatus.value = 'Synced'
          syncColor.value = 'green'
          showToast({ text: 'Sync completed successfully', duration: 2000, type: 'success' })
        } else {
          // If already initialized, check the last sync date
          let { lastSyncDate } = await BlueId.getLastSynchronizationDate()
          let diff = moment().diff(moment(lastSyncDate), 'days')

          if (diff >= 4) {
            console.log('Last sync was more than 4 days ago. Performing sync.')

            showToast({ text: 'Performing sync...', duration: 2000, type: 'default' })

            let apiLocks = await loadLocks()
            let { securedObjects } = await retrySync(apiLocks)

            // Complete the process
            showLoader.value = false
            syncStatus.value = 'Synced'
            syncColor.value = 'green'
            showToast({ text: 'Sync completed successfully', duration: 2000, type: 'success' })
          } else {
            // Last sync was recent, no need to sync again
            await loadLocks()
            showLoader.value = false
            syncStatus.value = 'Already Synced'
            syncColor.value = 'green'

            showToast({
              text: 'No need to sync, recently synced.',
              duration: 2000,
              type: 'success',
            })
          }
        }
      } catch (error) {
        console.error('Failed to sync:', error)
        syncStatus.value = 'Failed'
        syncColor.value = 'red'

        showToast({
          text: 'Failed to sync',
          duration: 4000,
          type: 'error',
        })
      }
    })

    return {
      locks,
      resync,
      showLoader,
      unlock,
      search,
      filteredLocks,
      lock,
      primaryColor,
      syncStatus,
      syncColor,
      isMobile,
      isBluetoothEnabled,
      enableBluetooth,
      isBleClientInitialized,
      initializeBleClient,
      snackbar,
      snackbarMessage,
      logo,
      hasFailed,
      failedMessage,
    }
  },
})
</script>

<style scoped>
.locks-view {}

.failure-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.failure-card {
  width: 300px;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Soft shadow to embed the card */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.failure-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  /* Slight hover effect for depth */
}

.failure-card-icon {
  color: #ff5252;
  /* Red accent for the error icon */
  margin-bottom: 16px;
}

.failure-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.failure-card-message {
  font-size: 1rem;
  color: #666;
  margin-bottom: 24px;
}

.loading-container {
  display: flex;
  justify-content: center;
  padding: 24px;
  align-items: center;
  background: radial-gradient(circle, rgba(240, 248, 255, 1) 0%, rgba(224, 224, 224, 0.5) 100%);
}

/* Custom card with dim transparency, smooth shadow, and soft borders */
.custom-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  width: 320px;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

/* Add hover animation for card effect */
.custom-card:hover {
  transform: scale(1.05);
}

/* Lock GIF styling */
.loading-lock {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

/* Beautiful, elegant text styling with subtle animation */
.loading-text {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  padding: 15px;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: pulse-text 2s infinite;
}

/* Styling for the secondary text */
.loading-text span {
  font-size: 16px;
  color: #666;
  display: block;
  margin-top: 5px;
}

/* Pulse animation for the text */
@keyframes pulse-text {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}

/* Fade-in animation for lock image */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slight transparency for elegance */
  backdrop-filter: blur(10px);
  /* Blur background content */
  color: #333333;
  /* Dark text color for contrast */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Soft shadow for elevation */
  border-radius: 8px;
  /* Rounded corners */
  padding: 8px 16px;
  /* Adjusted padding for spacing */
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  color: #333333;
  /* Matching text color */
  margin-left: -0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
  /* Adjust based on available space */
}

.search-bar {
  margin-bottom: 1rem;
  /* Spacing between search bar and list */
  transition: box-shadow 0.3s ease-in-out;
}

.search-bar:focus-within {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  /* Shadow effect on focus */
}

.lock-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  /* Reduced padding for less height */
  transition: background-color 0.3s ease;
}

.lock-details {
  overflow: hidden;
  /* Prevents text overflow */
  white-space: nowrap;
  /* Keeps the text in a single line */
  text-overflow: ellipsis;
  /* Adds ellipsis for overflowed text */
}

.lock-name {
  font-weight: bold;
  margin-right: 8px;
  /* Spacing between lock name and floor name */
}

.lock-floor {
  font-size: 0.75rem;
  /* Smaller font size for subtlety */
  color: #666;
  /* Lighter color for floor name */
}

.unlock-button {
  margin-left: auto;
  /* Pushes the button to the far right */
}

.unlock-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.beautiful-card {
  background-color: var(--v-card-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.beautiful-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: black;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #fff;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #ff3d00;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

.resync-button {
  background: linear-gradient(45deg, #333, #000);
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 8px 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>

<!-- LocksView Component
This component provides a user interface for managing and controlling access to locks via Bluetooth. It is designed to be used on mobile devices and offers several functionalities to enhance user experience.

The top toolbar includes a back button to navigate to the previous page and a title "Access Control". There is also a "Re-Sync" button that allows users to resynchronize the lock data. This button is disabled if the loader is active, Bluetooth is not enabled, the BLE client is not initialized, or the device is not mobile.

If the feature is accessed on a non-mobile device, a card is displayed informing the user that the feature is only available on mobile devices.

If Bluetooth is not enabled, a card is displayed with a button to enable Bluetooth.

If the BLE client is not initialized, a card is displayed with a button to initialize the Bluetooth client.

When the conditions are met (mobile device, Bluetooth enabled, BLE client initialized), the main content is displayed. This includes a search bar to filter locks by name or floor and a list of locks with their details. Each lock item includes the lock name, floor name, and an unlock button. Clicking the unlock button triggers the unlock process for the selected lock.

Visual feedback is provided to the user through various means:
- A loading indicator is shown while data is being loaded or actions are being performed.
- A custom loading card with a lock GIF and text is displayed during the unlocking process.
- Success, warning, or error alerts are shown using the Swal library. For example, when a lock is successfully unlocked, a success alert is displayed. If the unlock process fails, an error alert is shown.
- A failure card is displayed if the synchronization process fails, with a message and a button to retry the synchronization.

The component also includes a snackbar for displaying brief messages to the user, such as status updates or error messages.

Overall, this component provides a comprehensive and user-friendly interface for managing and controlling access to locks via Bluetooth on mobile devices. -->
